<template>
  <a :href="data.download_url" target="_blank" class="wrapper" v-on="$listeners">
    <b-icon :icon="data.icon" :size="data.size"></b-icon>
    <div style="margin-left: 4px;">
      <div style="font-size: 14px;line-height: 20px;">{{ data.title }}</div>
      <div style="font-size: 12px;line-height: 17px;">{{ data.subtitle }}</div>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    data: {
      required: true
    }
  },
  methods: {
    handleJump() {
      window.open(this.data.download_url, "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  text-decoration: none;
  width: 137px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #FFFFFF;
  border-radius: 8px;
}
</style>
