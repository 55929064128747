<template>
  <svg
    class="c-icon"
    aria-hidden="true"
    v-bind="$attrs"
    v-on="$listeners"
    :style="{
      width: `${size}px`,
      height: `${size}px`,
    }"
  >
    <use :href="`#bcut-${icon}`"></use>
  </svg>
</template>

<script>
export default {
  name: "v-icon",
  props: {
    icon: String,
    size: {
      type: Number,
      default: 14
    }
  }
};
</script>

<style scoped lang="scss">
.c-icon {
  overflow: hidden;
  fill: currentColor;
}
</style>
