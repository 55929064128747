<template>
  <div class="home-wrapper">
    <div class="navbar-wrapper">
      <div class="navbar-content">
        <div class="left">
          <img src="./assets/logo.png" class="logo pointer" @click="handleToTop" alt="">
          <img src="./assets/name.png" class="name pointer" @click="handleToTop" alt="">
          <div class="desc">bilibili 官方出品</div>
        </div>
        <div class="right">
          <a target="_blank" href="https://www.bilibili.com" class="bilibili pointer">
            <b-icon icon="bilibili" style="margin-right: 7px;" :size="24"></b-icon>
            <div>主站</div>
          </a>
          <a target="_blank" href="https://member.bilibili.com/platform/home" class="pointer">创作中心</a>
        </div>
      </div>
    </div>
    <div>
      <div class="header">
        <div class="header-1">必剪</div>
        <div class="header-2">B站出品，就很不同</div>
        <div class="header-3">
          <div class="button-wrapper">
            <qr-button class="qr"/>
            <download-button class="btn small" v-for="(btn, index) in buttons" :key="index" :data="btn"
                             @click="handleTrack(btn)"/>
            <br/>
          </div>
          <qr-button class="qr-small"></qr-button>
        </div>
      </div>
      <div class="block-1">
        <div class="title">必剪桌面版</div>
        <div class="subtitle">为 UP 主而生</div>
        <div class="content large">
          <hover-icon v-for="(btn, index) in hoverButtons" :active="index === activeSwiper" :data="btn" :key="btn.title"
                      @click="chooseSwiper(index)" @hover="chooseSwiper(index)"></hover-icon>
        </div>
        <div class="content small">
          <hover-icon v-for="(btn, index) in hoverButtons" :active="index === activeSwiper" :data="btn" :key="btn.title"
                      @click="chooseSwiper(index)" @hover="chooseSwiper(index)"></hover-icon>
        </div>
      </div>
      <div class="block-2">
        <div class="swiper">
          <div class="swiper-wrapper">
            <img class="swiper-slide" src="./assets/swiper-1.png" alt="">
            <img class="swiper-slide" src="./assets/swiper-2.png" alt="">
            <img class="swiper-slide" src="./assets/swiper-3.png" alt="">
            <img class="swiper-slide" src="./assets/swiper-4.png" alt="">
          </div>
        </div>
        <div class="tips">
          <div class="tips-content">
            更多特色功能敬请期待
          </div>
        </div>
      </div>
      <div class="block-3">
        <div class="desc desc-1">
          <div>
            <div class="title">高清录屏</div>
            <div class="subtitle">一键游戏实况解说，精彩时刻不错过</div>
            <div class="subtitle" style="color: rgb(71,71,71);text-align: left">（电脑端仅限Windows版本支持）</div>
          </div>
          <img src="./assets/desc-1.png" alt="">
        </div>
        <div class="desc desc-2">
          <div>
            <div class="title">全能剪辑</div>
            <div class="subtitle">全轨道展示，逐帧剪辑，剪辑从此得心应手</div>
          </div>
          <img src="./assets/desc-2.png" alt="">
        </div>
        <div class="desc desc-3">
          <div>
            <div class="title">一键投稿</div>
            <div class="subtitle">账号互通，投稿快人一步</div>
          </div>
          <img src="./assets/desc-3.png" alt="">
        </div>
      </div>
      <div class="block-4">
        <div class="title">丰富素材 海量音乐</div>
        <div class="subtitle">账号互通，投稿快人一步</div>
        <div class="music-wrapper">
          <div style="display: flex;flex: 1;justify-content: center">
            <div class="music" v-for="(music, index) in musics" :key="index"
                 :style="{'--music-bg': music.background}"
            >
              <b-icon style="background: white;border-radius: 23px;position: relative;z-index: 2" :icon="music.icon"
                      :size="46"/>
              <div style="margin-left: 13px; position: relative;z-index: 2">
                <div style="font-weight: 400;font-size: 24px;line-height: 34px;margin-bottom: 11px">{{ music.title }}
                </div>
                <div style="font-size: 16px;line-height: 22px;opacity: 0.7;margin-bottom: 22px;">{{ music.subtitle }}
                </div>
                <div style="font-size: 16px;line-height: 22px;">{{ music.bottom }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="block-5">
        <div class="block-5-content">
          <div class="img" v-for="(img, index) in imgs" :key="img" :style="{
          '--bg': 'url(' + img + ')'
        }" :class="{
          reverse: index % 2
        }"/>
          <div class="img-small" v-for="(img, index) in imgs" :key="index + imgs.length" :style="{
          '--bg': 'url(' + img + ')'
        }" :class="{
          reverse: index % 2
        }"/>
        </div>
      </div>
      <div class="block-6">
        上海哔哩哔哩动画有限公司<br/>互联网ICP备案：沪ICP备13044875号-4
      </div>
    </div>
  </div>
</template>


<script>
import DownloadButton from "./components/DownloadButton";
import QrButton from "./components/QrButton";
import HoverIcon from "./components/HoverIcon";
import Swiper from "swiper";
import axios from "axios";
import "swiper/swiper.min.css";
import bg01 from "./assets/01.png";
import bg02 from "./assets/02.png";
import bg03 from "./assets/03.png";
import bg04 from "./assets/04.png";
import bg05 from "./assets/05.png";
import bg06 from "./assets/06.png";
import bg07 from "./assets/07.png";
import bg08 from "./assets/08.png";
import bg09 from "./assets/09.png";
import bg10 from "./assets/10.png";

export default {
  components: {
    DownloadButton,
    QrButton,
    HoverIcon
  },
  data() {
    return {
      imgs: [
        bg01, bg02, bg03, bg04, bg05, bg06, bg07, bg08, bg09, bg10
      ],
      buttons: [
        {
          title: "iOS",
          subtitle: "下载",
          icon: "apple",
          size: 32,
          download_url: "https://apps.apple.com/cn/app/%E5%BF%85%E5%89%AA/id1299589486",
          track: "bcut_webhome_ios_download"
        },
        {
          title: "Android",
          subtitle: "下载",
          icon: "android",
          size: 32,
          download_url: "https://dl.hdslb.com/mobile/latest/bilistudio/android_bbs-master.apk",
          track: "bcut_webhome_android_download"
        },
        {
          title: "windows",
          subtitle: "下载",
          icon: "windows",
          size: 32,
          download_url: "",
          track: "bcut_webhome_pc_download"
        },
        {
          title: "macOS",
          subtitle: "下载",
          icon: "apple",
          size: 32,
          download_url: "//boss.hdslb.com/material/static/c84aaa7b0012e9e3a09347c244a32aa0/BCUT-3.3.11.dmg",
          track: "bcut_webhome_mac_download"
        }
      ],
      hoverButtons: [
        {
          icon: "tv-default",
          hover: "tv-hover",
          title: "海量素材",
          subtitle: "热门素材库  为创作加点料"
        },
        {
          icon: "text-default",
          hover: "text-hover",
          title: "语音字幕",
          subtitle: "一键语音转字幕 自动对齐超便捷"
        },
        {
          icon: "like-default",
          hover: "like-hover",
          title: "一键三连",
          subtitle: "特色三连 位置随心添加"
        },
        {
          icon: "upload-default",
          hover: "upload-hover",
          title: "B 站投稿",
          subtitle: "B 站账号登录  一键轻松投稿"
        }

      ],
      musics: [
        {
          icon: "music",
          background: "#9667FF",
          title: "宝藏音乐库",
          subtitle: "轻松获得热门BGM",
          bottom: "声而有趣"
        },
        {
          icon: "sticker",
          background: "#6A67FF",
          title: "海量贴纸",
          subtitle: "花样文字模板",
          bottom: "给视频加点料"
        },
        {
          icon: "transition",
          background: "#FF6695",
          title: "专业转场",
          subtitle: "神仙特效",
          bottom: "小白一秒变大神"
        }
      ],
      activeSwiper: 0,
      swiper: null
    };
  },
  mounted() {
    this.swiper = new Swiper(".swiper", {
      slidesPerView: 3,
      spaceBetween: -250,
      centeredSlides: true,
      loop: true
    });
    this.swiper.on("slideChange", ({ realIndex }) => {
      this.activeSwiper = realIndex;
    });
    axios.get("/x/bcut/pc/upgrade?version=0", {
      withCredentials: true
    }).then(res => {
      this.buttons[2].download_url = "//" + res.data.data.package_download_url.split("//")[1];
    });
    window.reportMsgObj.bcut_webhome_pv = "";
  },
  methods: {
    chooseSwiper(index) {
      this.activeSwiper = index;
      this.swiper.slideToLoop(index);
    },
    handleToTop() {
      document.documentElement.scrollTop = 0;
    },
    handleTrack(btn) {
      window.reportMsgObj[btn.track] = "";
    }
  }
};
</script>

<style lang="scss">
.pointer {
  &:hover {
    cursor: pointer;
  }
}

.swiper-slide {
  visibility: hidden;
  transform: scale(0.8);
}

.swiper-slide-prev, .swiper-slide-next, .swiper-slide-active {
  visibility: visible;
}

.swiper-slide-active {
  transform: scale(1);
}
</style>

<style lang="scss" scoped>
* {
  color: white;
}

.button-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.home-wrapper {
  position: relative;
  min-width: 375px;
  background: #1D1D21;
  overflow: hidden;

  .navbar-wrapper {
    position: fixed;
    top: 0;
    min-width: 375px;
    z-index: 1000;
    background: rgba(14, 14, 16, 0.8);
    width: 100%;

    .navbar-content {
      height: 54px;
      line-height: 54px;
      width: 980px;
      margin: 0 auto;
      overflow: hidden;
      font-size: 16px;

      .left, .right {
        float: left;
        display: flex;
        align-items: center;
        height: 100%;
      }

      .left {
        .logo {
          width: 42px;
          padding-right: 10px;
        }

        .name {
          width: 39px;
          padding-right: 14px;
          border-right: 1px solid rgba(255, 255, 255, 0.25);
        }

        .desc {
          padding-left: 14px;
          font-size: 16px;
        }
      }

      .right {
        float: right;

        .bilibili {
          display: flex;
          align-items: center;
          height: 100%;
          margin-right: 50px;
        }
      }
    }
  }


  .header {
    background-image: url("./assets/star.png");
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      background-image: url("./assets/left-light.png");
      width: 500px;
      height: 300px;
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
      left: 0;
      transform: translateX(-40%);
      top: 320px;
    }

    &:after {
      content: "";
      position: absolute;
      background-image: url("./assets/top-light.png");
      background-position: center;
      background-size: cover;
      left: 50%;
      transform: translateX(-20%);
      z-index: 1;
      width: 824px;
      top: -300px;
      height: 727px;
    }

    .header-1 {
      margin-top: 180px;
      font-weight: 200;
      font-size: 56px;
      line-height: 78px;
      position: relative;
      z-index: 2;
    }

    .header-2 {
      font-weight: 600;
      font-size: 48px;
      line-height: 67px;
      margin-bottom: 33px;
      position: relative;
      z-index: 2;
    }

    .header-3 {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 150px;
      position: relative;
      z-index: 2;

      .btn {
        margin-left: 12px;
      }

      .qr {
        display: flex;
      }

      .qr-small {
        display: none;
      }
    }
  }

  .block-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 27px;

    .title {
      color: #FFFFFF;
      font-weight: 600;
      font-size: 36px;
      line-height: 50px;
      position: relative;
      margin-bottom: 4px;

      &:before {
        content: "";
        position: absolute;
        width: 188px;
        height: 1px;
        background: linear-gradient(
            90deg, rgba(255, 255, 255, 0) -10.61%, #FFFFFF 88.56%);
        top: 50%;
        transform: translateY(-50%);
        right: 230px;
        opacity: 0.5;
      }

      &:after {
        content: "";
        position: absolute;
        width: 188px;
        height: 1px;
        background: linear-gradient(
            270deg, rgba(255, 255, 255, 0) -10.61%, #FFFFFF 88.56%);
        top: 50%;
        transform: translateY(-50%);
        left: 230px;
        opacity: 0.5;
      }
    }

    .subtitle {
      font-size: 24px;
      line-height: 34px;
      opacity: 0.7;
      margin-bottom: 67px;
    }

    .content {
      &.large {
        display: flex;
      }

      &.small {
        display: none;
      }

      width: 920px;
      margin: 0 auto;
      margin-bottom: 63px;
      justify-content: space-between;
    }
  }

  .block-2 {
    max-width: 1440px;
    margin: 0 auto 120px;

    .swiper {
      ::v-deep(.swiper-slide) {
        opacity: 0.3;
      }

      ::v-deep(.swiper-slide-active) {
        opacity: 1;
        z-index: 2;
      }
    }

    .tips {
      opacity: 0.4;
      display: flex;
      justify-content: center;

      .tips-content {
        margin-top: 33px;
        position: relative;
        font-size: 12px;
        line-height: 17px;
        color: #9FA1B2;

        &:before {
          content: "";
          position: absolute;
          border-top: 1px solid;
          width: 74px;
          border-image: -webkit-linear-gradient(
              -180deg, white, rgba(255, 255, 255, 0)) 10 10;
          top: 50%;
          transform: translateY(-50%);
          right: 140px;
          opacity: 0.5;
        }

        &:after {
          content: "";
          position: absolute;
          border-top: 1px solid;
          width: 74px;
          border-image: -webkit-linear-gradient(
              -180deg, rgba(255, 255, 255, 0), white) 10 10;
          top: 50%;
          transform: translateY(-50%);
          left: 140px;
          opacity: 0.5;
        }
      }
    }
  }


  .block-3 {
    margin-bottom: 80px;

    .desc + .desc {
      padding-top: 120px;
    }

    .desc-1, .desc-2, .desc-3 {
      position: relative;

      img {
        position: relative;
        width: 620px;
        //max-width: 100%;
        z-index: 2;
        padding: 0 60px;
      }
    }

    .desc-1 {
      &:after {
        z-index: 1;
        content: "";
        position: absolute;
        width: 280px;
        height: 280px;
        background-size: cover;
        background-position: center;
        left: calc(50% - 500px);
        top: -80px;
        background: radial-gradient(102.78% 102.78% at 37.01% 0%, rgba(255, 102, 149, 0.7) 0%, rgba(255, 102, 149, 0) 100%);
        opacity: 0.2;
        filter: blur(20px);
        transform: rotate(
            -85.6deg);
        border-radius: 140px;
      }
    }

    .desc-2 {
      &:after {
        z-index: 1;
        content: "";
        position: absolute;
        width: 243.88px;
        height: 243.88px;
        background: radial-gradient(102.78% 102.78% at 37.01% 0%, rgba(12, 182, 242, 0.62) 0%, rgba(12, 182, 242, 0) 100%);
        opacity: 0.2;
        filter: blur(20px);
        transform: rotate(
            105deg);
        left: calc(50% + 260px);
        top: -15%;
        border-radius: 130px;
      }
    }

    .desc-3 {
      &:after {
        z-index: 1;
        content: "";
        position: absolute;
        width: 223.21px;
        height: 223.21px;
        left: calc(50% - 650px);
        background: radial-gradient(102.78% 102.78% at 37.01% 0%, rgba(12, 182, 242, 0.62) 0%, rgba(12, 182, 242, 0) 100%);
        opacity: 0.3;
        filter: blur(20px);
        transform: rotate(
            -45deg);
        border-radius: 115px;
        top: -250px;
      }
    }


    .desc-1, .desc-3 {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: center;
    }

    .desc-2 {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .title {
      font-weight: 600;
      font-size: 36px;
      line-height: 50px;
      margin-bottom: 12px;
    }

    .subtitle {
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #9FA1B2;
    }
  }

  .block-4 {
    ::-webkit-scrollbar {
      width: 0 !important
    }

    & * {
      scrollbar-width: none;
    }

    position: relative;

    & > * {
      z-index: 2;
    }

    .title {
      text-align: center;
      font-weight: 600;
      font-size: 36px;
      line-height: 50px;
      margin-bottom: 12px;
    }

    .subtitle {
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #9FA1B2;
      margin-bottom: 88px;
    }

    .music-wrapper {
      display: flex;
      overflow: scroll;
      padding-bottom: 88px;

      .music {
        position: relative;
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        padding: 28px 24px 0;
        width: 258px;
        height: 171px;
        border-radius: 24px;
        margin-left: 32px;
        background: var(--music-bg);

        &:after {
          content: "";
          width: 182px;
          height: 117px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: -2px;
          background: var(--music-bg);
          filter: blur(50px);
          overflow: hidden;
        }

      }

      .music:last-child {
        margin-right: 32px;
      }
    }

    &:before {
      content: "";
      z-index: 1;
      position: absolute;
      width: 223.21px;
      height: 223.21px;
      left: calc(100% - 170px);
      top: 0;
      background: radial-gradient(102.78% 102.78% at 37.01% 0%, rgba(255, 102, 149, 0.7) 0%, rgba(255, 102, 149, 0) 100%);
      opacity: 0.3;
      filter: blur(20px);
      transform: rotate(
          27.73deg);
      border-radius: 120px;
    }
  }

  .block-5 {
    //display: flex;
    overflow: hidden;
    //justify-content: center;
    margin-bottom: 108px;
    position: relative;
    height: 477px;

    .block-5-content {
      display: flex;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    @keyframes anim {
      100% {
        background-position-y: 636px;
      }
    }
    @keyframes anim-reverse {
      100% {
        background-position-y: -636px;
      }
    }
    @keyframes anim-small {
      100% {
        background-position-y: 318px;
      }
    }
    @keyframes anim-reverse-small {
      100% {
        background-position-y: -318px;
      }
    }


    .img {
      background-image: var(--bg);

      display: block;

      & + .img {
        margin-left: 18px;
      }

      width: 135px;
      height: 477px;
      animation: anim 25s linear infinite;

      &.reverse {
        animation: anim-reverse 25s linear infinite;
      }
    }

    .img-small {
      background-image: var(--bg);

      display: none;
    }

    .img, .img-small {

      display: block;
      background-position-x: center;
      background-size: 100% auto;
      background-repeat: repeat-y;
      will-change: background-position-y;


    }
  }

  .block-6 {
    font-size: 12px;
    line-height: 24px;
    color: #9FA1B2;
    text-align: center;
    padding-bottom: 20px;
  }

  // 窄屏，包括移动端 + pad
  @media (max-width: 1024px) {

    .button-wrapper {
      width: 300px;
    }
    .navbar-wrapper {
      .navbar-content {
        box-sizing: border-box;
        width: 100%;
        margin: 0;
        padding: 0 14px;

        .left {
          .logo {
            width: 32px;
            padding-right: 3px;
          }

          .name {
            width: 30px;
            padding-right: 6px;
          }

          .desc {
            padding-left: 6px;
            font-size: 12px;
          }
        }

        .right {
          .bilibili {
            margin-right: 12px;
          }
        }

      }
    }
    .header {
      .header-1 {
        font-size: 36px;
        line-height: 50px;
      }

      .header-2 {
        font-size: 28px;
        line-height: 39px;
      }

      .header-3 {
        margin-bottom: 74px;

        .qr {
          display: none;
        }

        .qr-small {
          display: flex;
          width: 42px;
          height: 42px;
        }

        .btn {

          &:first-of-type {
            //margin-left: 0;
          }

          &.small {
            width: 106px;
            height: 42px;
          }

          margin-bottom: 34px;
        }
      }
    }

    .block-1 {
      margin-bottom: 10px;

      .title {
        font-size: 24px;
        line-height: 34px;

        &:before, &:after {
          width: 75px;
        }

        &:before {
          right: 150px;
        }

        &:after {
          left: 150px;
        }
      }

      .subtitle {
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 30px;
      }

      .content {
        margin-bottom: 12px;

        &.large {
          display: none;
        }

        &.small {
          width: 600px;
          display: flex;
        }
      }
    }

    .block-2 {
      margin-bottom: 50px;
    }
    .block-3 {
      .desc-1, .desc-2 {
        &:after {
          display: none;
        }
      }

      .desc-1, .desc-2, .desc-3 {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        .title {
          text-align: center;
          font-size: 24px;
          line-height: 34px;
        }

        .subtitle {
          font-size: 14px;
          line-height: 20px;
          text-align: center;
        }
      }
    }
    .block-4 {
      &:before {
        width: 98px;
        height: 98px;
        left: calc(100% - 60px);
        top: -80px;
      }

      .music-wrapper {
      }

      .title {
        text-align: center;
        font-size: 24px;
        line-height: 34px;
      }

      .subtitle {
        margin-bottom: 55px;
        font-size: 16px;
        line-height: 22px;
      }
    }
    .block-5 {
      height: 238.5px;

      .block-5-content {
        .img {
          display: none;
        }

        .img-small {
          display: block;
          width: 67.5px;
          height: 238.5px;

          & + .img-small {
            margin-left: 8px;
          }

          animation: anim-small 25s linear infinite;

          &.reverse {
            animation: anim-reverse-small 25s linear infinite;
          }

        }
      }
    }
  }


  @media (max-width: 700px) {

    .block-1 {
      .content.small {
        width: 280px;
        margin: 0 auto;
        flex-wrap: wrap;
      }
    }
  }
  @media (max-width: 500px) {
    .header {
      &:before {
        left: -100px;
        top: 280px;
        background-image: url("./assets/left-light-small.png");
      }

      &:after {
        width: 500px;
        height: 500px;
        top: -150px;
        left: 60%;

        background-image: url("./assets/right-light-small.png");
      }

      background-image: url("./assets/star-small.png");
    }
    .block-3 {
      .desc {
        padding: 0 !important;
      }

      img {
        padding: 40px 0 48px 0 !important;
        width: 327px !important;
      }

      .desc-3 {
        img {
          padding-bottom: 0 !important;
        }
      }
    }
  }
}

</style>
