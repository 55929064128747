<template>
  <home></home>
</template>

<script>
import Home from "./views/Home/index.vue";

export default {
  components: {
    Home
  }
};
</script>

<style lang="scss">
html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #1D1D21;
  font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Arial, PingFang SC, Hiragino Sans GB, Microsoft YaHei, sans-serif;
}

.pointer:hover {
  cursor: pointer;
}

.pointer-blue:hover {
  cursor: pointer;
}

a {
  text-decoration: none;
}
</style>
