<template>
  <div class="wrapper" @mouseenter="handleHover" @mouseleave="hover=false" v-on="$listeners">
    <div class="icon" :class="{hover: hover || $attrs.active}">
      <b-icon v-if="hover || $attrs.active" :icon="data.hover"></b-icon>
      <b-icon v-else :icon="data.icon"></b-icon>
    </div>
    <div class="title">{{ data.title }}</div>
    <div class="subtitle">{{ data.subtitle }}</div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      required: true
    }
  },
  data() {
    return {
      hover: false
    };
  },
  methods: {
    handleHover() {
      this.$emit("hover");
      this.hover = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {

  &:hover {
    cursor: pointer;
  }
  width: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .icon {
    width: 100px;
    height: 100px;
    box-sizing: border-box;
    border: 3px solid #AFB0BA;

    &.hover {
      border-color: #FF6695;
    }
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 44px!important;
      height: 44px!important;
    }
    margin-bottom: 20px;
  }
  .title {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 8px;
  }
  .subtitle {
    font-size: 14px;
    line-height: 20px;
    color: #9FA1B2;
    word-break: keep-all;
    text-align: center;
  }
}

@media (max-width: 1024px) {
  .wrapper {
    .icon {
      width: 72px;
      height: 72px;
    }
    margin-bottom: 34px;
  }

}
</style>
