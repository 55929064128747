import Vue from "vue";
import App from "./App.vue";
import Icon from "@/components/Frame/Icon";

Vue.config.productionTip = false;
Vue.component("b-icon", Icon);



new Vue({
  render: h => h(App)
}).$mount("#app");
