<template>
  <div class="wrapper" @mouseenter="hover=true" @mouseleave="hover=false" @click="hover=true">
    <b-icon icon="qrcode" :size="26"></b-icon>
    <div class="content" v-if="hover" ref="qr">
      <div class="qr">
        <img src="../../assets/download-qr-code.png" alt="">
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      hover: false
    };
  },
  mounted() {
    document.addEventListener("click", this.handleClick);
  },
  destroyed() {
    document.removeEventListener("click", this.handleClick);
  },
  methods: {
    handleClick(e) {
      this.hover = e.path.some(el => {
        return el === this.$refs.qr;
      });
    }
  }
};
</script>


<style lang="scss" scoped>
.wrapper {
  border-radius: 8px;
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  width: 48px;
  height: 48px;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  .content {
    position: absolute;
    width: 78px;
    height: 96px;
    bottom: 100%;
    .qr {
      border-radius: 10px;
      position: absolute;
      width: 100%;
      height: 78px;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      img {
        width: 64px;
        height: 64px;
      }

      &:after {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        background: white;
        transform: rotate(
            -45deg);
        left: calc(50% - 6px);
        bottom: -5px;
        border-bottom-left-radius: 3px;

      }
    }
  }
}
</style>
